import * as React from 'react'
import {RibbonProps} from '.'
import * as s from './ribbon.scss'

export const Ribbon: React.FC<RibbonProps> = ({text, t, className}: RibbonProps) =>
  text ? (
    <div className={className}>
      <div className={s.root} data-hook="ribbon">
        <div className={s.text}>{t(text)}</div>
      </div>
    </div>
  ) : null
