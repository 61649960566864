import {getFullLocale, isMobile} from '../../../../commons/selectors/environment'
import {isListLayout} from '../../../../commons/selectors/settings'
import {getRibbonText} from '../../../selectors/events'
import {isItemOpened} from '../../../selectors/layout'
import {
  isAdditionalComponentsHidden,
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isMobileSquareImage,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {getComponentConfig} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {DesktopItem as DesktopItemPresentation} from './desktop-item'
import {ItemOwnProps, ItemStateProps} from './interfaces'
import {MobileItem as MobileItemPresentation} from './mobile-item'

const mapRuntime = ({state, actions, host: {dimensions}, isRTL}: AppProps, {event}: ItemOwnProps): ItemStateProps => ({
  showRibbon: Boolean(getRibbonText(state, event)),
  showImage: isImageVisible(state),
  showDate: isDateVisible(state),
  showMembers: isMembersVisible(state, event),
  opened: isItemOpened(state, event.id),
  listShowFullDate: isFullDateVisible(state),
  listShowLocation: isLocationVisible(state),
  listShowDescription: isDescriptionVisible(state),
  showVenue: isVenueVisible(state, dimensions),
  additionalComponentsHidden: isAdditionalComponentsHidden(state, event.id),
  listLayout: isListLayout(getComponentConfig(state)),
  squareImage: isMobileSquareImage(state),
  fullLocale: getFullLocale(state),
  mobile: isMobile(state),
  rtl: isRTL,
})

export const DesktopItem = connect<ItemOwnProps, ItemStateProps>(mapRuntime)(DesktopItemPresentation)

export const MobileItem = connect<ItemOwnProps, ItemStateProps>(mapRuntime)(MobileItemPresentation)

export * from './interfaces'
