import {Component} from 'react'
import {AppLoadedProps} from '.'

export class AppLoaded extends Component<AppLoadedProps> {
  componentDidMount() {
    this.props.host.registerToComponentDidLayout(this.props.appLoaded)
    this.props.appLoaded643()
    if (performance && performance.mark) {
      performance.mark('events-widget-loaded')
    }
    if (this.props.viewMode === 'Site') {
      this.props.widgetLoaded(this.props.isHomePage)
    }
  }

  render() {
    return null
  }
}
